import cn from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Language from "../../enums/language.enum";
import ArticleProps from "../../interfaces/article.interface";
import styles from "./article.module.scss";

const Article = ({
  titleEn,
  titleFr,
  urlEn,
  urlFr,
  sourceEn,
  sourceFr,
  date,
  summaryEn,
  summaryFr,
  photoUrl,
}: ArticleProps) => {
  const { t, i18n } = useTranslation();
  const [showOverlay, setShowOverlay] = useState(false);

  const formattedDate = date.toLocaleDateString(
    i18n.language === Language.French ? "fr-CA" : "en-CA",
    {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    }
  );

  return (
    <article className={styles.article}>
      <div
        className={styles.image}
        style={{
          background: `url(${photoUrl}) no-repeat center center`,
          backgroundSize: "cover",
        }}
        onMouseEnter={() => {
          setShowOverlay(true);
        }}
        onMouseLeave={() => {
          setShowOverlay(false);
        }}
      >
        <div
          className={cn(styles.overlay, { [styles.overlayShown]: showOverlay })}
        >
          <span className={styles.summary}>
            {i18n.language === Language.French ? summaryFr : summaryEn}
          </span>
          <a
            className={styles.read}
            href={Language.French ? urlFr : urlEn}
            target="_blank"
            rel="noreferrer"
          >
            {t("article.readArticle")}
          </a>
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.sourceContainer}>
          <span className={styles.source}>
            {i18n.language === Language.French ? sourceFr : sourceEn}
          </span>
          <span className={styles.date}>{formattedDate}</span>
        </div>
        <hr className={styles.hr} />
        <h3 className={styles.title}>
          <a
            className={styles.a}
            href={Language.French ? urlFr : urlEn}
            target="_blank"
            rel="noreferrer"
          >
            {i18n.language === Language.French ? titleFr : titleEn}
          </a>
        </h3>
      </div>
    </article>
  );
};

export default Article;
