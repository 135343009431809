import { faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane, faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Language from "../../enums/language.enum";
import ProfileProps from "../../interfaces/profile.interface";
import styles from "./profile.module.scss";

const Profile = ({
  name,
  photoUrl,
  headlineEn,
  headlineFr,
  locationEn,
  locationFr,
  summaryEn,
  summaryFr,
  email,
  instagram,
  linkedIn,
  resumeUrl,
  showLocation,
  showSummary,
  showLinks,
  showEnter,
}: ProfileProps) => {
  const { t, i18n } = useTranslation();

  const location = (
    <span className={styles.location}>
      {i18n.language === Language.French ? locationFr : locationEn}
    </span>
  );

  const summary = (
    <>
      <hr className={styles.hr} />
      <p className={styles.summary}>
        {i18n.language === Language.French ? summaryFr : summaryEn}
      </p>
    </>
  );

  const links = (
    <>
      <hr className={styles.hr} />
      <ul className={styles.list}>
        <li className={styles.item}>
          <a
            className={styles.a}
            href={`mailto:${email}`}
            aria-label={t("ariaLabel.email")}
          >
            <FontAwesomeIcon className={styles.icon} icon={faPaperPlane} />
          </a>
        </li>
        <li className={styles.item}>
          <a
            className={styles.a}
            href={instagram}
            target="_blank"
            rel="noreferrer"
            aria-label={t("ariaLabel.instagram")}
          >
            <FontAwesomeIcon className={styles.icon} icon={faInstagram} />
          </a>
        </li>
        <li className={styles.item}>
          <a
            className={styles.a}
            href={linkedIn}
            target="_blank"
            rel="noreferrer"
            aria-label={t("ariaLabel.linkedIn")}
          >
            <FontAwesomeIcon className={styles.icon} icon={faLinkedinIn} />
          </a>
        </li>
        <li className={styles.item}>
          <a
            className={styles.a}
            href={resumeUrl}
            target="_blank"
            rel="noreferrer"
            download
            aria-label={t("ariaLabel.resume")}
          >
            <FontAwesomeIcon className={styles.icon} icon={faUser} />
          </a>
        </li>
      </ul>
    </>
  );

  const enter = (
    <Link className={styles.enter} to={"/home"}>
      {t("landing.enter")}
    </Link>
  );

  return (
    <div className={cn(styles.profile)}>
      <img className={styles.photo} src={photoUrl} alt={name} />
      <h1 className={styles.h1}>{name}</h1>
      <span className={styles.headline}>
        {i18n.language === Language.French ? headlineFr : headlineEn}
      </span>
      {showLocation && location}
      {showSummary && summary}
      {showLinks && links}
      {showEnter && enter}
    </div>
  );
};
export default Profile;
