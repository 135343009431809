import cn from "classnames";
import { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Portfolio from "../../components/portfolio/portfolio";
import Profile from "../../components/profile/profile";
import { getArticles, getProfile } from "../../contentful";
import ArticleEntity from "../../entities/article.entity";
import ProfileEntity from "../../entities/profile.entity";
import styles from "./home.module.scss";

const Home = () => {
  const [profile, setProfile] = useState<ProfileEntity>();
  const [articles, setArticles] = useState<ArticleEntity[]>();

  useEffect(() => {
    const getAndSetProfile = async () => {
      const profile = await getProfile();
      setProfile(profile);
    };
    getAndSetProfile();
  }, []);

  useEffect(() => {
    const getAndSetArticles = async () => {
      const articles = await getArticles();
      setArticles(articles);
    };
    getAndSetArticles();
  }, []);

  return (
    <div className={cn(styles.home, { [styles.fade]: !profile || !articles })}>
      <aside className={styles.profileContainer}>
        {profile && (
          <Profile
            {...profile}
            showLocation={true}
            showSummary={true}
            showLinks={true}
            showEnter={false}
          />
        )}
        {profile && <Footer name={profile.name} />}
      </aside>
      <main className={styles.portfolioContainer}>
        {profile && articles && <Portfolio {...profile} articles={articles} />}
      </main>
    </div>
  );
};

export default Home;
