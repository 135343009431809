import _ from "lodash";
import ArticleProps from "../../interfaces/article.interface";
import Article from "../article/article";
import styles from "./article-list.module.scss";

const ArticleList = ({ articles }: ListProps) => {
  return (
    <ul className={styles.list}>
      {articles.map((article) => {
        return (
          <li key={_.uniqueId("article-")} className={styles.item}>
            <Article {...article} />
          </li>
        );
      })}
    </ul>
  );
};

interface ListProps {
  articles: ArticleProps[];
}

export default ArticleList;
