import { Entry } from "contentful";
import Locale from "../enums/locale.enum";

class ProfileEntity {
  name: string;
  photoUrl: string;
  headlineEn: string;
  headlineFr: string;
  locationEn: string;
  locationFr: string;
  summaryEn: string;
  summaryFr: string;
  email: string;
  instagram: string;
  linkedIn: string;
  resumeUrl: string;

  constructor(entry: Entry<any>) {
    this.name = entry.fields.name[Locale.English];
    this.photoUrl = `https:${
      entry.fields.photo[Locale.English].fields.file[Locale.English].url
    }`;
    this.headlineEn = entry.fields.headline[Locale.English];
    this.headlineFr = entry.fields.headline[Locale.French];
    this.locationEn = entry.fields.location[Locale.English];
    this.locationFr = entry.fields.location[Locale.French];
    this.summaryEn = entry.fields.summary[Locale.English];
    this.summaryFr = entry.fields.summary[Locale.French];
    this.email = entry.fields.email[Locale.English];
    this.instagram = entry.fields.instagram[Locale.English];
    this.linkedIn = entry.fields.linkedIn[Locale.English];
    this.resumeUrl = `https:${
      entry.fields.resume[Locale.English].fields.file[Locale.English].url
    }`;
  }
}

export default ProfileEntity;
