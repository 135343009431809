import { useTranslation } from "react-i18next";
import PortfolioProps from "../../interfaces/portfolio.interface";
import ArticleList from "../article-list/article-list";
import styles from "./portfolio.module.scss";

const Portfolio = ({ articles }: PortfolioProps) => {
  const { t } = useTranslation();

  return (
    <section className={styles.portfolio}>
      <h2>{t("writingPortfolio")}</h2>
      <div className={styles.articleListContainer}>
        <ArticleList articles={articles} />
      </div>
    </section>
  );
};

export default Portfolio;
