import cn from "classnames";
import { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Profile from "../../components/profile/profile";
import { getProfile } from "../../contentful";
import ProfileEntity from "../../entities/profile.entity";
import styles from "./landing.module.scss";

const Landing = () => {
  const [profile, setProfile] = useState<ProfileEntity>();

  useEffect(() => {
    const getAndSetProfile = async () => {
      const profile = await getProfile();
      setProfile(profile);
    };
    getAndSetProfile();
  }, []);

  return (
    <main>
      <div className={cn(styles.landing, { [styles.fade]: !profile })}>
        {profile && (
          <>
            <div className={styles.profileContainer}>
              <Profile
                {...profile}
                showLocation={false}
                showSummary={false}
                showLinks={false}
                showEnter={true}
              />
            </div>
            <Footer name={profile.name} />
          </>
        )}
      </div>
    </main>
  );
};

export default Landing;
