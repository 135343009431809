import Router from "./router";
import "./styles/app.scss";

const App = () => (
  <div className="app">
    <Router />
  </div>
);

export default App;
