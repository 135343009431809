import { Entry } from "contentful";
import Locale from "../enums/locale.enum";

class ArticleEntity {
  titleEn: string;
  titleFr: string;
  urlEn: string;
  urlFr: string;
  sourceEn: string;
  sourceFr: string;
  date: Date;
  summaryEn: string;
  summaryFr: string;
  photoUrl: string;

  constructor(entry: Entry<any>) {
    this.titleEn = entry.fields.title[Locale.English];
    this.titleFr = entry.fields.title[Locale.French];
    this.urlEn = entry.fields.url[Locale.English];
    this.urlFr = entry.fields.url[Locale.French];
    this.sourceEn = entry.fields.source[Locale.English];
    this.sourceFr = entry.fields.source[Locale.French];
    this.date = new Date(entry.fields.date[Locale.English]);
    this.summaryEn = entry.fields.summary[Locale.English];
    this.summaryFr = entry.fields.summary[Locale.French];
    this.photoUrl =
      entry.fields.photo[Locale.English].fields.file[Locale.English].url;
  }
}

export default ArticleEntity;
