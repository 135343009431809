import { useTranslation } from "react-i18next";
import FooterProps from "../../interfaces/footer.interface";
import styles from "./footer.module.scss";

const Footer = ({ name }: FooterProps) => {
  const { t, i18n } = useTranslation();
  return (
    <footer className={styles.footer}>
      <span className={styles.text}>
        &copy; {name} |{" "}
        <button
          className={styles.button}
          onClick={() =>
            i18n.changeLanguage(i18n.language.includes("fr") ? "en" : "fr")
          }
        >
          {t("footer.language")}
        </button>
      </span>
    </footer>
  );
};

export default Footer;
