import { createClient } from "contentful";
import { ACCESS_TOKEN, ENVIRONMENT_ID, SPACE_ID } from "./config";
import ArticleEntity from "./entities/article.entity";
import ProfileEntity from "./entities/profile.entity";
import ContentType from "./enums/content-type.enum";

const contentfulClient = createClient({
  space: SPACE_ID,
  environment: ENVIRONMENT_ID,
  accessToken: ACCESS_TOKEN,
});

const getProfileEntries = () => {
  return contentfulClient.getEntries({
    content_type: ContentType.Profile,
    locale: "*",
    order: "-sys.updatedAt",
    include: 1,
  });
};

const getArticleEntries = () => {
  return contentfulClient.getEntries({
    content_type: ContentType.Article,
    locale: "*",
    order: "-fields.date",
    include: 1,
  });
};

export const getProfile = async () => {
  const entries = await getProfileEntries();
  return new ProfileEntity(entries.items[0]);
};

export const getArticles = async () => {
  const entries = await getArticleEntries();
  return entries.items.map((entry) => new ArticleEntity(entry));
};
